import { Title as SMTitle } from '@solidjs/meta';

interface TitleProps {
  readonly children?: string;
}

export default function Title(props: TitleProps) {
  const title = () => {
    if (props.children) {
      return `${props.children} | Rádio J-Hero`;
    }

    return 'Rádio J-Hero | Do seu jeito, do seu gosto!';
  };

  return <SMTitle>{title()}</SMTitle>;
}
